import config from "@/config";

class CoolDownUtils {

    addUserBtnCd() {
        localStorage.setItem(config.USER_CD_KEY, JSON.stringify(new Date().getTime()));
    }

    hasUserBtnCd() {
        const day = localStorage.getItem(config.USER_CD_KEY);
        return day && new Date().getTime() - JSON.parse(day) <= 1500;
    }

    userBtnCd() {
        if(!this.hasUserBtnCd()) {
            this.addUserBtnCd();
            return false;
        }
        else return true;
    }
}

export default new CoolDownUtils();