import config from "@/config";
import api from "@/services/api";

const initialState = {monitoringData: null, voteData: null, salesData: null, serverDescData: null, serversData: null}

export const commonModule = {
    namespaced: true,
    state: initialState,
    actions: {
        getMonitoring({commit}) {
            return api.get(config.API_URL + 'common/monitoring').then(response => {
                commit('setMonitoring', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getVoters({commit}) {
            return api.get(config.API_URL + 'common/lastVoters').then(response => {
                commit('setVoters', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getSales({commit}) {
            return api.get(config.API_URL + 'common/sales').then(response => {
                commit('setSales', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getServerDescData({commit}, name) {
            return api.get(config.API_URL + 'server/' + name).then(response => {
                commit('setServerDescData', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getServers({commit}) {
            return api.get(config.API_URL + 'server/all').then(response => {
                commit('setServers', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
    },
    mutations: {
        setMonitoring(state, data) {
            state.monitoringData = data;
        },
        setVoters(state, data) {
            state.voteData = data;
        },
        setSales(state, data) {
            state.salesData = data;
        },
        setServerDescData(state, data) {
            state.serverDescData = data;
        },
        setServers(state, data) {
            state.serversData = data;
        }
    },
    getters: {
        getMonitoringData(state) {
            return state.monitoringData;
        },
        getVoteData(state) {
            return state.voteData;
        },
        getSales(state) {
            return state.salesData;
        },
        getServerDescData(state) {
            return state.serverDescData;
        },
        getServers(state) {
            return state.serversData;
        }
    }
}