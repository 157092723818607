import api from "./api";
import config from "@/config";
import tokenService from "@/services/TokenService";

class AuthService {

    login(user) {
        return api.post(
            config.API_URL + "auth/signin", {
                login: user.login,
                password: user.password
            })
            .then(response => {
                if(response.data.accessToken)
                    tokenService.setUser(JSON.stringify(response.data));

                return response.data;
            });
    }

    logout() {
        tokenService.removeUser();
    }

    register(user) {
        return api.post(
            config.API_URL + "auth/signup", {
                login: user.login,
                email: user.email,
                password: user.password,
                confPassword: user.confPassword,
                captchaToken: user.token,
                isRulesAccepted: user.rulesAccepted,
                inviter: localStorage.getItem(config.REF_KEY)
        })
        .then(response => {
            return response.data;
        });
    }

    addRef(login) {
        localStorage.setItem(config.REF_KEY, login);
    }
}

export default new AuthService();