import Vue from 'vue'
import App from './App.vue'
import Toast from "vue-toastification";
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import config from "./config";
import setupInterceptors from "@/services/SetupInterceptors";

import "vue-toastification/dist/index.css";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/style.css';
import './assets/main.scss'
import 'animate.css'

Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Toast, {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false
});

setupInterceptors(store, router);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
document.title = config.PROJECT_DOMAIN + " - Главная"
