import api from "@/services/api";
import config from "@/config";

const initialState = { user: null, tfaData: null, tfaCode: null, toastMessage: null };

export const profile = {
    namespaced: true,
    state: initialState,
    actions: {
        addToastMessage({commit}, message) {
            commit('addToastMessage', message)
        },
        getCurrentUser({commit}) {
            return api.get(config.API_URL + "profile/").then(response => {
                commit('setCurrentUser', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        setNewPassword({commit}, request) {
            return api.post(config.API_URL + 'profile/changePassword',request).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        uploadSkin({commit}, formData) {
            return api.post(config.API_URL + 'profile/uploadSkin',formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        uploadCloak({commit}, formData) {
            return api.post(config.API_URL + 'profile/uploadCloak',formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        deleteSkin({commit}) {
            return api.post(config.API_URL + 'profile/deleteSkin').then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        deleteCloak({commit}) {
            return api.post(config.API_URL + 'profile/deleteCloak').then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        getTfaData({commit}) {
            return api.get(config.API_URL + "profile/getTfaData").then(response => {
                commit('setTfaData', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        deleteTfa({commit}, tfaType) {
            return api.post(config.API_URL + 'profile/deleteTfa/' + tfaType).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        generationTfaCode({commit}, tfaType) {
            return api.post(config.API_URL + 'profile/genCode/' + tfaType).then(response => {
                commit('setTfaCode', response.data);
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        }
    },
    mutations: {
        setCurrentUser(state, user) {
            state.user = user;
        },
        setTfaData(state, data) {
            state.tfaData = data;
        },
        setTfaCode(state, data) {
            if(data?.status === 200)
                state.tfaCode = data?.message;
        },
        addToastMessage(state, message) {
            state.toastMessage = message
        },
    },
    getters: {
        getCurUser(state) {
            return state.user;
        },
        getTfaData(state) {
            return state.tfaData
        },
        getTfaCode(state) {
            return state.tfaCode;
        },
        getToastMessage(state) {
            return state.toastMessage;
        }
    }
}