import api from "@/services/api";
import config from "@/config";

const initialState = { serverList: null, server: null, serverProps: null, serverProperty: null, serverMods: null,
        serverMod: null, donateGroup: null, donateGroupList: null, donateCase:null, donateCasesList:null };

export const cabinetAdmin = {
    namespaced: true,
    state: initialState,
    actions: {
        //update or create new server
        updateServer({commit}, srv) {
            return api.post(config.API_URL + "admin/cabinet/server/updateServer", srv, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        updateServerProps({commit}, property) {
            return api.post(config.API_URL + "admin/server/property/update", property)
                .then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        updateServerMod({commit}, property) {
            return api.post(config.API_URL + "admin/server/mod/update", property)
                .then(response => {
                    return response.data;
                }).then(success => {
                    return Promise.resolve(success.data);
                }, error => {
                    return Promise.reject(error);
                });
        },
        //update or create new donate group
        updateDonateGroup({commit}, group) {
            return api.post(config.API_URL + "admin/cabinet/donategroup/updateDonateGroup", group, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        updateDonateCase({commit}, randomBox) {
            return api.post(config.API_URL + "admin/cabinet/donatecase/updateDonateCase", randomBox,
                {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        clearServerStorage({commit}) {
            commit('clearServerStorage');
        },
        clearServerPropStorage({commit}) {
            commit('clearServerPropStorage');
        },
        clearServerModStorage({commit}) {
            commit('clearServerModStorage');
        },
        clearDonateGroupStorage({commit}) {
            commit('clearDonateGroupStorage');
        },
        clearDonateCaseStorage({commit}) {
            commit('clearDonateCaseStorage');
        },
        deleteServer({commit},id) {
            return api.post(config.API_URL + "admin/cabinet/server/deleteServer/" + id).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        deleteServerMod({commit},id) {
            return api.post(config.API_URL + "admin/server/mod/delete/" + id).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        deleteServerProps({commit},id) {
            return api.post(config.API_URL + "admin/server/property/delete/" + id).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        deleteDonateGroup({commit},id) {
            return api.post(config.API_URL + "admin/cabinet/donategroup/deleteDonateGroup/" + id).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        deleteDonateCase({commit},id) {
            return api.post(config.API_URL + "admin/cabinet/donatecase/deleteDonateCase/" + id).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getServers({commit}) {
            return api.get(config.API_URL + 'admin/cabinet/server/all').then(response => {
                commit("getServers", response.data);
               return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getServerById({commit}, id) {
            return api.get(config.API_URL + 'admin/cabinet/server/' + id).then(response => {
                commit("getServerById", response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getServerMods({commit}) {
            return api.get(config.API_URL + 'admin/server/mod/all').then(response => {
                commit("getServerMods", response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getServerModById({commit}, id) {
            return api.get(config.API_URL + 'admin/server/mod/' + id).then(response => {
                commit("getServerModById", response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getServerProps({commit}) {
            return api.get(config.API_URL + 'admin/server/property/all').then(response => {
                commit("getServerProps", response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getServerPropById({commit}, id) {
            return api.get(config.API_URL + 'admin/server/property/' + id).then(response => {
                commit("getServerPropById", response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getDonateGroups({commit}) {
            return api.get(config.API_URL + 'admin/cabinet/donategroup/all').then(response => {
                commit("getDonateGroups", response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getDonateGroupById({commit}, id) {
            return api.get(config.API_URL + 'admin/cabinet/donategroup/' + id).then(response => {
                commit("getDonateGroupById", response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getDonateCases({commit}) {
            return api.get(config.API_URL + 'admin/cabinet/donatecase/all').then(response => {
                commit("donateCases", response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getDonateCaseById({commit}, id) {
            return api.get(config.API_URL + 'admin/cabinet/donatecase/' + id).then(response => {
                commit("getDonateCaseById", response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
    },
    mutations: {
        getServers(state, list) {
            state.serverList = list;
        },
        getServerById(state, srv) {
            state.server = srv;
        },
        getServerMods(state, list) {
            state.serverMods = list;
        },
        getServerModById(state, mod) {
            state.serverMod = mod;
        },
        getServerProps(state, list) {
            state.serverProps = list;
        },
        getServerPropById(state, prop) {
            state.serverProperty = prop;
        },
        getDonateGroups(state, list) {
            state.donateGroupList = list;
        },
        getDonateGroupById(state, dg) {
          state.donateGroup = dg;
        },
        donateCases(state, list) {
          state.donateCasesList = list;
        },
        getDonateCaseById(state, dc) {
            state.donateCase = dc;
        },
        clearServerStorage(state) {
            state.server = null;
        },
        clearServerModStorage(state) {
            state.serverMod = null;
        },
        clearServerPropStorage(state) {
            state.serverProperty = null;
        },
        clearDonateGroupStorage(state) {
            state.donateGroup = null;
        },
        clearDonateCaseStorage(state) {
            state.donateCase = null;
        }
    },
    getters: {
        getServers(state) {
            return state.serverList;
        },
        getServerMods(state) {
            return state.serverMods;
        },
        getServerProps(state) {
            return state.serverProps;
        },
        getServerPropById(state) {
            return state.serverProperty;
        },
        getServerModById(state) {
            return state.serverMod;
        },
        getServerById(state) {
            return state.server;
        },
        getDonateGroups(state) {
            return state.donateGroupList;
        },
        getDonateGroupById(state) {
            return state.donateGroup;
        },
        getDonateCases(state) {
            return state.donateCasesList;
        },
        getDonateCaseById(state) {
            return state.donateCase;
        }
    }
}