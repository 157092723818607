<template>
  <div class="">
    <auth-popup v-bind:show="authPopUpVisible" @showRestorePopup="updateRestorePopup" @showRegPopup="updateRegPopup" @closeAuthPopup="updateAuthPopup"></auth-popup>
    <reg-popup v-bind:show="regPopupVisible" @showAuthPopup="closeRegAndOpenAuth" @closeRegPopup="updateRegPopup"></reg-popup>
    <restore-password-popup v-bind:show="showRestore" @click="updateRestorePopup" @closeRestorePopup="updateRestorePopup">
    </restore-password-popup>

    <b-navbar v-show="getMessage" toggleable="lg" type="dark">
      <b-container>
        <logotype class="mr-3"></logotype>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item @click="goToHome">Главная</b-nav-item>
            <b-nav-item v-if="isAdmin" @click="goToAdmin">ПУ</b-nav-item>
            <b-nav-item @click="goToRules">Правила</b-nav-item>
<!--            <li class="nav-item nav-shop-btn" @click="goToShop">-->
<!--              <a data-v-69e26463="" href="" v-on:click.prevent="goToShop" target="_self" class="nav-link" style="color: #0095ff">Магазин</a>-->
<!--            </li>-->
            <b-nav-item @click="goToShop" style="color:#33CCFF;">Магазин</b-nav-item>
            <b-nav-item @click="goToServers">Сервера</b-nav-item>
<!--            <span @mouseover="onServersIn" @mouseleave="onServersLeave" @click="onServersIn" class="server-dropdown-item">-->
<!--              <b-nav-item>Сервера-->
<!--                <ul class="server-dropdown" :class="{isOpenServerList}">-->
<!--                  <li @click="goToServer('hitech')">HiTech</li>-->
<!--                  <li @click="goToServer('tmrpg')">TechnoMagicRPG</li>-->
<!--&lt;!&ndash;                  <li @click="goToServer('tmsky')">TechnoMagicSky</li>&ndash;&gt;-->
<!--                  <li @click="goToServer('evorix')">Industrial 1.19.2</li>-->
<!--&lt;!&ndash;                  <li @click="goToServer('divinerpg')">DivineRPG</li>&ndash;&gt;-->
<!--                </ul>-->
<!--                </b-nav-item>-->
<!--            </span>-->
            <b-nav-item class="nav-shop-btn" @click="goToDonate">Донат</b-nav-item>
<!--            <li class="nav-item nav-shop-btn" @click="goToDonate">-->
<!--              <a data-v-69e26463="" href="" v-on:click.prevent="goToDonate" target="_self" class="nav-link" style="color: darkorange">Донат</a>-->
<!--            </li>-->
            <b-nav-item @click="goToTop">Голосование</b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <strong v-if="loggedIn">{{userName}}</strong>
                <strong v-else>Авторизация</strong>
              </template>
              <b-dropdown-item @click="updateAuthPopup" v-if="!loggedIn" class="nav-auth-menu">Авторизация</b-dropdown-item>
              <b-dropdown-item @click="updateRegPopup" v-if="!loggedIn" class="nav-auth-menu">Регистрация</b-dropdown-item>
              <b-dropdown-item @click="goToProfile" v-if="loggedIn" class="nav-auth-menu">Профиль</b-dropdown-item>
              <b-dropdown-item @click="logout" v-if="loggedIn" class="nav-auth-menu">Выход</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
    <!--<div class="header-description">
      <h1 class="header-description-page-name">{{name}}</h1>
      <p class="header-description-page">{{description}}</p>
    </div> -->
  </div>
</template>

<script>
import config from "@/config";
import Logotype from "@/components/main/Logotype"

export default {
  name: "Header",
  components: {Logotype,
    RestorePasswordPopup: () => import('@/components/pop-ups/RestorePasswordPopup'),
    authPopup: () => import('@/components/pop-ups/auth-popup'),
    regPopup: () => import('@/components/pop-ups/register-popup'),
  },
  data() {
    return {
      orangeColor: 'darkorange !important',
      isOpenServerList: false,
      authPopUpVisible: false,
      regPopupVisible: false,
      showRestore: false,
    }
  },
  computed: {
    getMessage() {
      let message = this.$store.getters["profile/getToastMessage"];
      if(message) {
        this.$store.dispatch("profile/addToastMessage", null)
        this.$toast.error(message);
      }

      return true
    },
    userName() {
      return this.$store.getters["auth/getCurUser"]?.login;
    },
    isAdmin() {
      if (!this.loggedIn) return false;
      return this.$store.getters["auth/getCurUser"].group.toUpperCase() === 'ADMIN'
    },
    loggedIn() {
      return this.$store.getters["auth/getLoggedUser"];
    },
  },
  methods: {
    onServersLeave() {
      this.isOpenServerList = false;
    },
    onServersIn() {
      this.isOpenServerList = true;
    },
    goToProfile() {
      this.$router.push("/profile/main").catch(()=>{});
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/').catch(()=>{});
      this.$toast.success("Вы вышли из своего аккаунта!")
    },
    updateAuthPopup() {
      this.authPopUpVisible = !this.authPopUpVisible;
    },
    updateRegPopup() {
      this.regPopupVisible = !this.regPopupVisible;
    },
    updateRestorePopup() {
      this.showRestore = !this.showRestore;
    },
    closeRegAndOpenAuth() {
      this.regPopupVisible = !this.regPopupVisible;
      this.authPopUpVisible = !this.authPopUpVisible;
    },

    //router
    goToHome() {
      this.$router.push("/").catch(()=>{});
    },
    goToAdmin() {
      this.$router.push("/admin").catch(()=>{});
    },
    goToRules() {
      this.$router.push("/rules").catch(()=>{});
    },
    goToShop() {
      this.$router.push("/shop").catch(()=>{});
    },
    goToServers() {
      this.$router.push("/servers").catch(()=>{});
    },
    goToDonate() {
      this.$router.push("/donate/classic").catch(()=>{});
    },
    goToServer(srv) {
      this.$router.push("/servers/" + srv).catch(()=>{});
    },
    goToTop() {
      this.$router.push("/top/vote").catch(()=>{});
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/main";

$routes-link-font-size: 16px;
$routes-link-font-weight: bold;

$routes-link-offset: 60px;

a.nav-link {
  text-transform: uppercase;
  font-weight: $routes-link-font-weight;
  text-decoration: none;
}

.navbar-dark .navbar-nav .nav-item {
  color: $gray-text-color;
  transition: 0.3s;
  text-decoration: none;

  &.nav-shop-btn {
    color: darkorange
  }

  &:hover {
    color: white;
    transition: 0.3s;
  }
}

//.navbar-light .navbar-nav .nav-link {
//  color: $gray-text-color;
//  text-transform: uppercase;
//  transition: 0.3s;
//
//  &:not(:last-child) {
//    margin-right: 60px;
//  }
//
//  &:hover {
//    transition: 0.3s;
//    color: white;
//  }
//}

.server-dropdown-item {
  position: relative;
  height: auto;
  z-index: 35;
}

.server-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  padding: 0;
  list-style-type: none;
  background-color: $dark-gray;

  li {
    //border-bottom: 1px solid $main-blue-color;
    color: $gray-text-color;
    text-align: left;
    text-transform: none;
    padding: 11px 9px 0 9px;
    transition: 0.3s;

    &:hover {
      color: white;
      transition: 0.3s;
    }
  }

}

.isOpenServerList {
  display: block;
}

.navbar-nav > ul > .dropdown-menu { background-color: #FF0000; }
</style>