<template>
  <div>
    <shop-item-popup @closeShopItemPopup="changePopup" v-bind:show="showPopup"
                     v-bind:shop-item="saleItem"
                     v-bind:item-price="priceResult" v-bind:item-sale="getSale"></shop-item-popup>
    <div class="item-block">
      <div class="shop-amount-info">{{saleItem.amount}} {{getAmountLang}}</div>
      <div class="item-title">{{saleItem.name}}</div>
      <div class="sale-donate" v-if="getSale">-{{getSale}}%</div>
      <div class="item-img" style="align-items: center"><img :src="getImg" class="item-img">
      </div>
      <div @click="changePopup" class="btn-box">Купить за {{priceResult}} <br>{{coinName}}</div>
    </div>
  </div>
</template>

<script>

import config from "@/config";
import cm from "@/services/utils/CommonUtil";
import ShopItemPopup from "../pop-ups/shop/ShopItemPopup";

export default {
  name: "SalesItem",
  components: {ShopItemPopup},

  data() {
    return {
      coinName: config.REAL_MONEY_NAME,
      showPopup: false,
      server: null
    }
  },
  props: {
    saleItem: {
      type: Object,
      default: null,
      required: true,
    },
  },
  computed: {
    getImg() {
      return this.saleItem?.img ? config.API_URL + "uploads/items/" + this.saleItem.img : '';
    },
    priceResult() {
      return parseFloat(cm.floorValue(this.saleItem?.price - this.saleItem?.price
          * ((this.saleItem?.sale) / 100)));
    },
    getSale() {
      return this.saleItem?.sale;
    },
    getAmountLang() {
      return cm.declOfNum(this.saleItem.amount, cm.amount_array);
    }
  },
  methods: {
    changePopup() {
      if(!this.$store.getters["auth/getLoggedUser"]) {
        this.$toast.error("Необходима авторизация!");
        return;
      }
      this.showPopup = !this.showPopup;
    },
  },
}
</script>

<style scoped>

</style>