import api from "@/services/api";
import config from "@/config";

const initialState = { userList: null,
                        someUser: null,
                        groups: null};

export const userAdmin = {
    namespaced: true,
    state: initialState,
    actions: {
        getUserList({commit}, page) {
            api.get(config.API_URL + 'admin/users/all', {
                params: {
                    startPos: page - 1, endPos: config.MAX_RESULT_PER_PAGE
                }
            }).then(response => {
                commit('setUserList', response.data);
                return Promise.resolve(response.data);
            });
        },
        getSimilarUserList({commit}, data) {
            api.get(config.API_URL + 'admin/users/all', {
                params: {
                    startPos: data.page - 1,
                    endPos: config.MAX_RESULT_PER_PAGE,
                    login: data.name,
                }
            }).then(response => {
                commit('setUserList', response.data);
                return Promise.resolve(response.data);
            });
        },
        getSomeUser({commit}, id) {
            api.get(config.API_URL + 'admin/users/user/'+id).then(response => {
                commit('setSomeUser', response.data);
                return Promise.resolve(response.data);
            });
        },
        getSiteGroups({commit}) {
            api.get(config.API_URL + 'admin/groups').then(response => {
                commit('setSiteGroups', response.data);
                return Promise.resolve(response.data);
            });
        },
        updateUser({commit}, user) {
            return api.post(config.API_URL + 'admin/users/update/' + user.id, user).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        deleteUser({commit}, id) {
           return api.post(config.API_URL + 'admin/users/delete/' + id).then(response => {
                return response.data;
            }).then(response => {
                response.data.then(success => {
                    return Promise.resolve(success.data);
                }, error => {
                    return Promise.reject(error);
                });
            });
        }
    },
    mutations: {
        setUserList(state, data) {
            state.userList = data
        },
        setSomeUser(state, data) {
            state.someUser = data;
        },
        setSiteGroups(state, data) {
            state.groups = data;
        }
    },
    getters: {
        getUserList(state) {
            return state.userList;
        },
        getSomeUser(state) {
            return state.someUser;
        },
        getSiteGroups(state) {
            return state.groups;
        }
    }
}