import config from "@/config";
import api from "@/services/api";

const initialState = { categories: null, category: null,
    items: null,
    item: null,
    itemCommands: null};

export const adminShop = {
    namespaced: true,
    state: initialState,
    actions: {
        getCategories({commit}) {
            return api.get(config.API_URL + 'admin/shop/categories').then(response => {
                commit('setCategories', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getItems({commit}) {
            return api.get(config.API_URL + 'admin/shop/items').then(response => {
                commit('setItems', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getItemsByServerId({commit}, id) {
            return api.get(config.API_URL + 'admin/shop/items', {
                params: {
                    srvId: id
                }
            }).then(response => {
                commit('setItems', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getCategoryById({commit}, id) {
            return api.get(config.API_URL + 'admin/shop/categories/category/' + id).then(response => {
                commit('setCategory', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getShopItemById({commit}, id) {
            return api.get(config.API_URL + 'admin/shop/items/item/' + id).then(response => {
                commit('setShopItem', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getCommandsByItemId({commit}, id) {
            return api.get(config.API_URL + 'admin/shop/commandsByItemId/' + id).then(response => {
                commit('setCommands', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        updateCategory({commit}, data) {
            return api.post(config.API_URL + "admin/shop/updateCategory", data
            ).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        updateShopItem({commit}, data) {
            return api.post(config.API_URL + "admin/shop/updateShopItem", data, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        updateCommands({commit}, data) {
            return api.post(config.API_URL + "admin/shop/updateCommands", data
            ).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        deleteCategory({commit}, id) {
            return api.post(config.API_URL + "admin/shop/deleteCategory/" +  id
            ).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        deleteShopItem({commit}, id) {
            return api.post(config.API_URL + "admin/shop/deleteShopItem/" +  id
            ).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        clearCategoryStorage({commit}) {
            commit('clearCategoryStorage');
        },
        clearItemStorage({commit}) {
            commit('clearItemStorage');
        }
    },
    mutations: {
        setCategories(state, data) {
            state.categories = data;
        },
        setItems(state, data) {
            state.items = data;
        },
        setCategory(state, data) {
          state.category = data;
        },
        setShopItem(state, data) {
          state.item = data;
        },
        setCommands(state, data) {
          state.itemCommands = data;
        },
        clearCategoryStorage(state) {
            state.category = null;
        },
        clearItemStorage(state) {
            state.item = null;
        }
    },
    getters: {
        getCategories(state) {
            return state.categories;
        },
        getItems(state) {
          return state.items
        },
        getCurrentCategory(state) {
            return state.category;
        },
        getCurrentItem(state) {
          return state.item;
        },
        getCommands(state) {
            return state.itemCommands;
        }
    }
}