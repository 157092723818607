import config from "@/config";
import api from "@/services/api";

const initialState = {
    logsData: null,
}

export const adminLogs = {
    namespaced: true,
    state: initialState,
    actions: {
        getAllLogs({commit}, page) {
            return api.get(config.API_URL + 'admin/logs/', {
                params: {
                    page: page - 1, size: config.MAX_RESULT_PER_PAGE
                }
            }).then(response => {
                commit('setLogsData', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getLogsByType({commit}, data) {
            return api.get(config.API_URL + 'admin/logs/', {
                params: {
                    type: data.type,
                    page: data.page - 1, size: config.MAX_RESULT_PER_PAGE
                }
            }).then(response => {
                commit('setLogsData', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
    },
    mutations: {
        setLogsData(state, data) {
            state.logsData = data;
        }
    },
    getters: {
        getLogsData(state) {
            return state.logsData;
        }
    }
}