import config from "@/config";
import api from "@/services/api";

const initialState = {
    giftCodeData: null, giftCode: null
}

export const adminGiftCodes = {
    namespaced: true,
    state: initialState,
    actions: {
        getAllGiftCodes({commit}) {
            return api.get(config.API_URL + 'admin/cabinet/giftcode/getAll').then(response => {
                commit('setGiftCodesData', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getGiftCodeById({commit}, id) {
            return api.get(config.API_URL + 'admin/cabinet/giftcode/get', {
                params: {
                    id: id
                }
            }).then(response => {
                commit('setGiftCode', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        updateGiftCode({commit}, data) {
            return api.post(config.API_URL + "admin/cabinet/giftcode/update", data
            ).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        deleteGiftCode({commit}, id) {
            return api.post(config.API_URL + "admin/cabinet/giftcode/delete/" +  id
            ).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        clearGiftCode({commit}) {
            commit('setGiftCode', null);
        }
    },
    mutations: {
        setGiftCodesData(state, data) {
            state.giftCodeData = data;
        },
        setGiftCode(state, data) {
            state.giftCode = data;
        }
    },
    getters: {
        getGiftCodesData(state) {
            return state.giftCodeData;
        },
        getGiftCode(state) {
            return state.giftCode;
        }
    }
}