import config from "@/config";

class TokenService {

    getUser() {
        return JSON.parse(localStorage.getItem(config.USER_KEY));
    }

    setUserFromJson(user) {
        localStorage.setItem(config.USER_KEY, JSON.stringify(user));
    }

    setUser(user) {
        localStorage.setItem(config.USER_KEY, user);
    }

    removeUser() {
        localStorage.removeItem(config.USER_KEY);
    }

    updateAccessToken(token) {
        this.setUserFromJson(token);
    }

    getAccessToken() {
        let user = this.getUser();
        return user?.accessToken;
    }

    getRefreshToken() {
        let user = this.getUser();
        return user?.refreshToken;
    }
}

export default new TokenService();