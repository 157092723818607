import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from "./AuthModule";
import { profile} from "./UserModule";
import { userAdmin } from "@/store/admin/AdminUserModule";
import { cabinetAdmin } from "@/store/admin/AdminCabinetModule";
import { adminShop } from "@/store/admin/AdminShopModule";
import { adminLogs } from "@/store/admin/AdminLogsModule";
import { adminGiftCodes } from "@/store/admin/AdminGiftCodeModule";
import { shopModule } from "@/store/ShopModule.js";
import { cartModule } from "@/store/CartModule";
import { cabinet } from "@/store/CabinetModule";
import { commonModule } from "@/store/CommonModule";
import { playersTopModule } from "@/store/PlayersTopModule";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  modules: {
    playersTopModule, commonModule, cartModule, adminLogs, adminGiftCodes, shopModule, adminShop, cabinet, cabinetAdmin,
    userAdmin, profile, auth
  }
})
