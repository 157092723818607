import config from "@/config";
import api from "@/services/api";

const initialState = {categories: null, items: null, servers: null}

export const shopModule = {
    namespaced: true,
    state: initialState,
    actions: {
        getCategories({commit}) {
            return api.get(config.API_URL + 'shop/categories').then(response => {
                commit('setCategories', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getItems({commit}, filter) {
            return api.get(config.API_URL + 'shop/items/', filter).then(response => {
                commit('setItems', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        getServers({commit}) {
            return api.get(config.API_URL + 'shop/servers').then(response => {
                commit('setServers', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        buyShopItem({commit}, data) {
            return api.post(config.API_URL + 'shop/buyItem', data).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
    },
    mutations: {
        setCategories(state, data) {
            state.categories = data;
        },
        setServers(state, data) {
            state.servers = data;
        },
        setItems(state, data) {
            state.items = data;
        }
    },
    getters: {
        getCategories(state) {
            return state.categories;
        },
        getServers(state) {
            return state.servers;
        },
        getItems(state) {
            return state.items;
        }
    }
}