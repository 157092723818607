class CommonUtil {

    constructor() {
        this.amount_array = ["штука", "штуки", "штук"];
        this.month_array = ["месяц", "месяца", "месяцев"];
    }

    prepareImage(image) {
        let img = image.files[0];
        if (!img) return null;

        if (img.type !== "image/png" && img.type !== "image/jpeg") return null;

        const formData = new FormData();
        formData.append("img", img);

        return formData;
    }

    pushIdToArrayFromObjects(array, objects, clear) {
        if(clear) array = [];
        for(let i = 0; i < objects.length; i++) {
            array.push(objects[i].id);
        }

        return array;
    }

    parseDay(day) {
        return ("0" + day).slice(-2);
    }

    parseMoth(month) {
        return ("0" + (month + 1)).slice(-2)
    }

    createDateTime(date, time) {
        if(date == null) return "";

        if(time == null) time = "00:00:00"

        return date + " " + time
    }

    getDate(datetime) {
        if(datetime == null) return null

        return datetime.split(" ")[0]
    }

    getTime(datetime) {
        if(datetime == null) return null

        let timeSplit = datetime.split(" ");

        if(timeSplit?.length !== 2) return null

        return timeSplit[1]
    }

    floorValue(value) {
        return Number.isInteger(value) ? value : value.toFixed(2);
    }

    declOfNum(n, text_forms) {
        n = Math.abs(n) % 100;
        let n1 = n % 10;
        if (n > 10 && n < 20) { return text_forms[2]; }
        if (n1 > 1 && n1 < 5) { return text_forms[1]; }
        if (n1 === 1) { return text_forms[0]; }
        return text_forms[2];
    }
}
export default new CommonUtil();