<template>
  <div @click="goToServerDesc(name)" class="monitoring-item">
    <h4 class="monitoring-name mb-3">{{name}}</h4>
    <span class="mt-3">{{onlineText}}</span>
    <div class="__progress">
      <div class="progress-bar" v-bind:style="barWidth"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MonitoringItem",
  computed: {
    onlineText() {
      if(this.online < 0)
        return "Сервер выключен";
      return "Онлайн: " + this.online + " из " + this.maxOnline;
    },
    barWidth() {
      if(this.online < 0)
        return {
          width: '100%',
          backgroundColor: 'red',
          'box-shadow': '0px 0px 20px 11px rgb(116 21 21)'
        };
      else if(this.online > 0)
        return {
          width: (this.online * 100 / this.maxOnline ) + '%',
          backgroundColor: '#3376D3',
          'box-shadow': '#0062cc 0px 0px 20px 3px'
        };
      return {
        width: '0%',
        backgroundColor: '#3376D3',
      };
    }
  },
  props: {
    name: {
      required: true
    },
    online: {
      required: true,
      type: Number,
    },
    maxOnline: {
      required: true,
      type: Number,
    }
  },
  methods: {
    goToServerDesc(name) {
      this.$router.push("/server/" + name)
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/main";

.monitoring-item {
  height: 115px;
  width: 335px;
  background-color: $input-color;
  display: inline-block;
  color: $gray-text-color;
  border-radius: 7px;
  padding-right: 11px;
  padding-left: 11px;
  cursor: pointer;
}

.monitoring-name {
  padding-top: 9px;
  color: white;
  font-family: $font-bold;
}

.__progress {
  border-radius: 5px;
  background-color: $gray;
  height: 1.5rem;
  margin: auto;
}
.progress-bar {
  height: 100%;
  border-radius: 5px;
}

</style>