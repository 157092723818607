import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store/index'
import config from "@/config";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Майнкрафт сервера с модами'
    },
  },
  {
    path: '/restore/:token',
    name: 'restore',
    component: () => import('../views/Restore.vue'), //lazy loaded
    meta: {
      title: 'Восстановление пароля'
    },
  },
  {
    path: '/ref/:user',
    name: 'ref',
    component: () => import('../views/Ref.vue'), //lazy loaded
    meta: {
      title: 'Регистрация'
    },
  },
  {
    path: '/reg-verify/:token',
    name: 'RegistrationVerify',
    component: () => import('../views/VerifyRegistration.vue'), //lazy loaded
    meta: {
      title: 'Подтверждение регистрации'
    },
  },
  {
    path: '/rules',
    name: 'Rules',
    component: () => import('../views/Rules.vue'), //lazy loaded
    meta: {
      title: 'Правила'
    },
  },
  {
    path: '/donate',
    name: 'Donate',
    meta: {
      title: 'Описание доната'
    },
    component: () => import('../views/Donate.vue'), //lazy loaded
    children: [
      {
        path: 'ruby',
        component: () => import('../components/donate/RubyDonate'),
        meta: {
          title: 'Описание доната'
        },
      },
      {
        path: 'potato',
        component: () => import('../components/donate/PotatoDonate'),
        meta: {
          title: 'Описание доната'
        },
      },
      {
        path: 'divinepvp',
        component: () => import('../components/donate/DivinePvPDonate'),
        meta: {
          title: 'Описание доната'
        },
      },
      {
        path: 'classic',
        component: () => import('../components/donate/ClassicDonate'),
        meta: {
          title: 'Описание доната'
        },
      },
    ]
  },
  {
    path: '/servers',
    meta: {
      title: 'Описание серверов'
    },
    component: () => import('../views/Servers.vue'), //lazy loaded
    children: [
      {
        path: ':serverName',
        component: () => import('../components/servers/ServerDescTemplate'),
        meta: {
          title: 'Описание сервера'
        },
      },
    ]
  },
  {
    path: '/server/:serverName',
    meta: {
      title: 'Описание сервера'
    },
    component: () => import('../components/servers/ServerDescTemplate'), //lazy loaded
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      requiresAuth: true,
      title: 'Личный кабинет'
    },
    component: () => import('../views/Profile.vue'), //lazy loaded
    children: [
      {
        path: 'main',
        component: () => import('../components/profile/ProfileMain'),
        meta: {
          title: 'Личный кабинет'
        },
      },
      {
        path: 'settings',
        component: () => import('../components/profile/ProfileSettings'),
        meta: {
          title: 'Настройки'
        },
      },
      {
        path: 'groups',
        component: () => import('../components/profile/ProfileDonateGroups'),
        meta: {
          title: 'Личный кабинет'
        },
      },
      {
        path: 'balance',
        component: () => import('../components/profile/ProfileBalance'),
        meta: {
          title: 'Личный кабинет'
        },
      },
      {
        path: 'cases',
        component: () => import('../components/profile/ProfileDonateCases'),
        meta: {
          title: 'Личный кабинет'
        },
      },
      {
        path: 'referrals',
        component: () => import('../components/profile/ProfileReferral'),
        meta: {
          title: 'Личный кабинет'
        },
      },
      {
        path: 'history',
        component: () => import('../components/profile/ProfileHistory'),
        meta: {
          title: 'История'
        },
      },
      {
        path: 'cart',
        component: () => import('../components/profile/ProfileCart'),
        meta: {
          title: 'Инвентарь'
        },
      },
    ]
  },
  {
    path: '/shop',
    name: 'shop',
    meta: {
      requiresAuth: true,
      title: 'Магазин'
    },
    component: () => import('../views/Shop.vue'), //lazy loaded
  },
  {
    path: '/top',
    name: 'top',
    meta: {
      requiresAuth: false,
      title: 'Топ игроков'
    },
    component: () => import('../views/Top.vue'), //lazy loaded
    children: [
      {
        path: 'vote',
        component: () => import('../components/top/TopVote'),
        meta: {
          title: 'Топ голосующих'
        },
      },
    ]
  },
  {
    path: '/admin',
    name: 'admin-main',
    meta: {
      requiresAdmin: true
    },
    component: () => import('../views/admin/AdminMain.vue') //lazy loaded
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    meta: {
      requiresAdmin: true,
      title: 'Управление игроками'
    },
    component: () => import('../views/admin/UserList.vue') //lazy loaded
  },
  {
    path: '/admin/users/user/:userId',
    name: 'admin-user',
    meta: {
      requiresAdmin: true,
      title: 'Изменение игрока'
    },
    component: () => import('../views/admin/User.vue')
  },
    //CABINET
  {
    path: '/admin/cabinet',
    name: 'admin-cabinet',
    meta: {
      requiresAdmin: true,
      title: 'Управление лк'
    },
    component: () => import('../views/admin/ServerSettingsMain.vue'),
    children: [
      {
        path: 'servers',
        component: () => import('../components/admin/cabinet/Servers'),
      },
      {
        path: 'giftcodes',
        component: () => import('../components/admin/cabinet/GiftCodes'),
      },
      {
        path: 'groups',
        component: () => import('../components/admin/cabinet/DonateGroups'),
      },
      {
        path: 'cases',
        component: () => import('../components/admin/cabinet/DonateCases'),
      },
      {
        path: 'categories',
        component: () => import('../components/admin/shop/Categories.vue'),
      },
      {
        path: 'mods',
        component: () => import('../components/admin/cabinet/ServerMods'),
      },
      {
        path: 'props',
        component: () => import('../components/admin/cabinet/ServerProps'),
      },
      {
        path: 'items',
        component: () => import('../components/admin/shop/Items.vue'),
      }
    ]
  },
  //CABINET
  {
    path: '/admin/logs',
    name: 'admin-logs',
    meta: {
      requiresAdmin: true,
      title: 'Просмотр логов'
    },
    component: () => import('../views/admin/AdminLogs.vue'),
  },
  {
    path :'*',
    component: Home
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(store.getters["auth/getLoggedUser"]) {
      next();
      return
    }
    store.dispatch("profile/addToastMessage", "Необходима авторизация")
    next('/');
  } else if(to.matched.some(record => record.meta.requiresAdmin)) {
    if(store.getters["auth/getLoggedUser"] && store.getters["auth/getCurUser"].group.toUpperCase() === "ADMIN") {
      next();
      return
    }
    next('/');
  } else {
    next();
  }
});

const DEFAULT_TITLE = config.PROJECT_NAME;

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = DEFAULT_TITLE + " - " + to.meta.title || DEFAULT_TITLE;
  });
});

export default router
