import tokenService from "@/services/TokenService";
import api from "./api";
import config from "@/config";

const setup = (store, router, toast) => {
    api.interceptors.request.use((config) => {
        const token = tokenService.getAccessToken();
        if(token) {
            config.headers["Authorization"] = token;
            // Headers = {Accept=[application/json;charset=UTF-8],
            // Content-Type=[application/json;charset=UTF-8]
        }
        return config;
    }, error => {
        return Promise.reject(error);
    });

    //todo check refreshtoken request count
    api.interceptors.response.use((res) => {
        return res;
    },  async (err) => {
        const originalConfig = err.config;
        if(!originalConfig.url.endsWith("/auth/signin") && err.response) {

            //access token expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const rs =  await api.post("/auth/refreshtoken", {
                        refreshToken: tokenService.getRefreshToken(),
                    });

                    tokenService.updateAccessToken(rs.data)
                    store.dispatch('auth/refreshToken', rs.data)

                    return api(originalConfig);
                } catch (e) {
                    return Promise.reject(e);
                }
            }

            //refresh token expired
            if(err.response.status === 403) {
                //toast.error("Сессия устарела! Пройдите авторизация вновь!")
                store.dispatch('auth/logout');
                router.push("/").catch(()=>{});
            }
            return Promise.reject(err);
        }
        return Promise.reject(err);
    });
}

export default setup;