<template>
  <b-col sm="12" class="block-title-main">
    <p class="block-title-text" >{{title}}</p>
  </b-col>
</template>

<script>
export default {
  name: "BlockTitle",
  props: {
    title: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/main";

.block-title-main {
  display: block;
  margin-bottom: 55px;
  margin-top: 135px;
}

.block-title-text {
  color: white;
  font-family: $font-bold;
  font-size: 35px;

  &::before {
    content: url("../../assets/img/block-title.webp");
    position: absolute;
    margin: -45px 0 0 -37px;
  }

}

</style>