<template>
  <transition
      leave-active-class="animate__animated animate__zoomOut"
  >
    <div class="d-popup" v-if="show" @click="closePopup">
      <div class="d-popup__content item-popup-content animate__animated animate__bounceIn" @click.stop>
        <b-row>
          <b-col lg="4">
            <div class="info-image-block">
              <img :src="getImg" class="info-image">
            </div>
          </b-col>
          <b-col lg="8">
            <div class="header-info">{{shopItem.name}}</div>
          </b-col>
          <b-col class="item-info-block" v-if="shopItem.desc && shopItem.desc.length > 0" sm="12" style="text-align: left">
            <div><b>Описание: {{shopItem.desc}}</b></div>
          </b-col>
          <b-col sm="12" class="mt-3 pl-3 pr-3 item-info-block">
            <div v-if="server" class="item-info-line">
              <b>Сервер: </b><span class="right-act">{{server.name}}</span>
            </div>
            <div v-if="ecoType === ecoTypes.REAL" class="item-info-line">
              <b>Цена за {{shopItem.amount}} {{getAmountLang}}: </b><span class="right-act">{{shopItem.price}} {{moneyName}}</span>
            </div>
            <div v-if="ecoType === ecoTypes.BONUSES" class="item-info-line">
              <b>Цена за {{shopItem.amount}} {{getAmountLang}}: </b><span class="right-act">{{shopItem.bonusPrice}} {{moneyName}}</span>
            </div>
            <div v-if="getItemSale && ecoType === ecoTypes.REAL" class="item-info-line">
              <b>Скидка: </b><span class="right-act">{{getItemSale}}%</span>
            </div>
            <div v-if="getItemSale && ecoType === ecoTypes.REAL" class="item-info-line">
              <b>Цена со скидкой: </b><span class="right-act">{{singlePriceResult}} {{moneyName}}</span>
            </div>

            <div v-if="shopItem.canEnchant">
              <div class="enchant-spoiler mt-3">
                <div @click="updateSpoiler" class="enchant-spoiler-title">
                  <span>Зачаровать</span>
                </div>
                <div class="enchant-spoiler-body">
                  <div v-for="e in shopItem.enchants" class="item-enchant-line mt-1">
                    <div class="item-enchant-name" style="width: 250px">{{e.lang}}:</div>
                    <div class="pl-3 right-act item-enchant-range">
                      <input :id="e.name" type="range" step="1" min="0"
                             :max="shopItem.maxLevel" value="0"
                             v-on:change="onChangeEnchant($event, e)">
                    </div>
                    <div class="item-enchant-level">0</div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col v-if="!server" sm="6" class="mt-4 item-info-block" style="text-align: left;">
            <div><b>Сервер: </b></div>
          </b-col>
          <b-col v-if="!server && setServers" sm="6" class="mt-3">
            <b-form-select v-model="tempServer.selected" :options="tempServer.options"></b-form-select>
          </b-col>
          <b-col sm="6" class="mt-4 item-info-block" style="text-align: left;">
            <div><b>Количество: </b></div>
          </b-col>
          <b-col sm="6" class="mt-3">
            <div data-v-f482d064="" class="d-flex flex-row">
              <button @click="dincAmount" class="btn btn-primary">-</button>
              <b-input v-model="amount" id="select-amount-item" type="number" :min="1" :max="999" class="text-center form-control"></b-input>
              <button @click="incAmount" type="button" class="btn btn-primary">+</button>
            </div>
          </b-col>
          <b-col sm="4" class="mt-3">
            <button @click="closePopup" class="btn d-btn-medium">Отмена</button>
          </b-col>
          <b-col sm="8" class="mt-3">
            <button @click="buyItem" class="btn d-btn-medium">Купить за {{priceResult}} {{moneyName}}</button>
          </b-col>
        </b-row>
      </div>
    </div>
  </transition>
</template>

<script>
import config from "@/config";
import notificationService from "@/services/NotificationService";
import cm from "@/services/utils/CommonUtil";
import coolDownUtils from "@/services/utils/CoolDownUtils";
import {ECO_TYPE} from "@/models/EcoType";

export default {
  name: "ShopItemPopup",
  data() {
    return {
      sendRequest: false,
      ecoTypes: ECO_TYPE,
      amount: 1,
      enchantPrice: 0,
      enchantShow: false,
      enchants: new Map(),
      tempServer: {
        selected: null,
        options: [
          {value: null, text: 'Выберите сервер', disabled: true},
        ]
      },
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    shopItem: {
      type: Object,
      default: null,
      required: true
    },
    server: {
      type: Object,
      default: null,
      required: false
    },
    itemPrice: {
      type: Number,
      default: 0,
      required: false
    },
    itemSale: {
      type: Number,
      default: 0,
      required: false
    },
    ecoType: {
      type: String,
      default: ECO_TYPE.REAL,
      required: false
    }
  },
  computed: {
    setServers() {
      if(this.server != null) return;
      const servers = this.shopItem.servers;
      if(servers) {
        //clearData
        this.tempServer.options = [];
        this.tempServer.options.push({value: null, text: 'Выберите сервер', disabled: true});
        //set data
        servers.forEach((srv => {
          this.tempServer.options.push({value: srv.id, text: srv.name});
        }));
      }
      return servers;
    },
    singlePriceResult() {
      return this.itemPrice;
    },
    moneyName() {
      if(this.ecoType === ECO_TYPE.REAL)
        return cm.declOfNum(this.priceResult, config.REAL_MONEY_ARRAY);
      if(this.ecoType === ECO_TYPE.BONUSES)
        return cm.declOfNum(this.priceResult, config.BONUS_MONEY_ARRAY);
    },
    priceResult() {
      if(this.amount < 1 || this.amount > 10000) {
        this.amount = 1;
      }
      let totalPrice = this.itemPrice + this.enchantPrice;
      return cm.floorValue(totalPrice * this.amount)
    },
    getImg() {
      return this.shopItem?.img ? config.API_URL + "uploads/items/" +this.shopItem.img : '';
    },
    getAmountLang() {
      return cm.declOfNum(this.shopItem.amount, ["штуку", "штуки", "штук"]);
    },
    getItemSale() {
      return this.itemSale;
    },
  },
  mounted() {
  },
  methods: {
    closePopup() {
      this.enchants.clear();
      this.enchantPrice = 0;
      this.enchantShow = false;
      this.$emit("closeShopItemPopup")
    },
    buyItem() {
      if(this.sendRequest) return;

      let user = this.$store.getters["profile/getCurUser"];
      if(user && this.ecoType === this.ecoTypes.REAL && this.$store.getters["profile/getCurUser"].realMoney < this.priceResult)
        return this.$toast.error("Недостаточно средств!");

      if(user && this.ecoType === this.ecoTypes.BONUSES && this.$store.getters["profile/getCurUser"].bonuses < this.priceResult)
        return this.$toast.error("Недостаточно средств!");

      if(this.server == null && this.tempServer.selected == null) return;

      this.sendRequest = true;

      this.$store.dispatch("shopModule/buyShopItem", {
        serverId: this.server != null ? this.server.id : this.tempServer.selected,
        iid: this.shopItem.id,
        amount: this.amount,
        ecoType: this.ecoType,
        enchants: this.buyEnchants()
      }).then(success =>{
        notificationService.alert(this.$toast, success);
        this.$store.dispatch('profile/getCurrentUser');
      }, error => {
        notificationService.alert(this.$toast, error);
      }).finally(() => this.sendRequest = false);
    },
    dincAmount() {
      this.amount--;
      if(this.amount < 1) {
        this.amount = 1;
      }
    },
    incAmount() {
      this.amount++;
      if(this.amount > 999) {
        this.amount = 1;
      }
    },
    onChangeEnchant(event, enchant) {
      let enchantId = enchant;
      let value = event.target.value;
      this.enchants.set(enchantId, value);
      event.target.parentElement.parentElement.lastElementChild.innerHTML = value;
      this.calculateEnchantPrice();
    },
    calculateEnchantPrice() {
      let result = 0;
      this.enchants.forEach(function(value,key) {
        if(value > 0) {
          result+= value * key.price;
        }
      });

      this.enchantPrice = result;
    },
    buyEnchants() {
      let enchants = [];
      this.enchants.forEach(function(value,key) {
        if(value > 0) {
          enchants.push({enchant: key.name, level: value})
        }
      });

      return enchants
    },
    updateSpoiler(event) {
      let spBody = event.target.parentElement.parentElement
          .getElementsByClassName("enchant-spoiler-body")[0];
      if(!spBody.classList.contains("open")) {
        spBody.classList.add("open");
      } else
        spBody.classList.remove("open");
      //this.enchantShow = !this.enchantShow
    }
  }
}
</script>

<style scoped lang="scss">

.item-enchant-line {
  display: flex;
}

.enchant-spoiler-title {
  font-size: 19px;
  text-align: center;
  background-color: $input-color;
  cursor: pointer;
  padding: 9px 0;
}

.enchant-spoiler-body {
  text-align: center;
  background-color: $border-light;
  cursor: pointer;
  padding: 5px 11px;
  font-size: 15px;

  overflow: hidden;
  opacity: 0;
  max-height: 0;
  transition: all 0.3s;
}

.enchant-spoiler-body.open {
  overflow: auto;
  opacity: 1;
  max-height: 1000px;
  overflow-y:hidden;
}

.item-enchant-level {
  width: 35px;
  margin-left: 7px;
}

.item-enchant-range {
  width: 100%;

  input[type="range"] {
    width: 100%;
    -webkit-appearance: none;
    margin: 5px 0;
    background: $input-color;
  }

}

</style>