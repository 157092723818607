import config from "@/config";
import api from "@/services/api";

const initialState = {cartItems: null}

export const cartModule = {
    namespaced: true,
    state: initialState,
    actions: {
        getCartItems({commit}) {
            return api.get(config.API_URL + 'cart/getCartItems').then(response => {
                commit('setCartItems', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        },
        giveCartItem({commit}, id) {
            return api.post(config.API_URL + 'cart/giveItems/' + id).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
    },
    mutations: {
        setCartItems(state, data) {
            state.cartItems = data;
        }
    },
    getters: {
        getCartItems(state) {
            return state.cartItems;
        }
    }
}