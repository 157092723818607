class NotificationService {
    alert(toast, data) {
        if(data && data.message && data.status) {
            if(Number.parseInt(data.status) >= 200 && Number.parseInt(data.status) <= 299) {
                toast.success(data.message);
            } else if(Number.parseInt(data.status) >= 400 && Number.parseInt(data.status) <= 499) {
                toast.error(data.message);
            }
        } else {
            let erJson = (data.response && data.response.data) || data.message || data.toString();
            if(erJson.message) {
                toast.error(erJson.message);
            }else if(erJson.error && erJson.error === "Internal Server Error" || erJson.error && erJson.error === "Bad Request") {
                toast.error("Ошибка сервера. Обратитесь к администрации!");
            } else {
                let objArray = Object.keys(erJson).map((key) => [Number(key), erJson[key]]);
                if (objArray.length < 10) {
                    for (let k in erJson) {
                        toast.error(erJson[k]);
                    }
                } else {
                    toast.error("Произошла ошибка! обратитесь к администрации!");
                }
            }
        }
    }
}

export default new NotificationService();