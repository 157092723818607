<template>
  <div class="">
    <start-game-popup v-if="startGameShow" @closeStartGamePopup="updateStartGamePopup"
                      @openRegPopup="updateRegPopup" :show="startGameShow"></start-game-popup>

    <auth-popup v-bind:show="authPopUpVisible" @showRestorePopup="updateRestorePopup" @showRegPopup="updateRegPopup" @closeAuthPopup="updateAuthPopup"></auth-popup>
    <register-popup v-bind:show="regPopupVisible" @showAuthPopup="closeRegAndOpenAuth" @closeRegPopup="updateRegPopup"></register-popup>
    <restore-password-popup v-bind:show="showRestore" @click="updateRestorePopup" @closeRestorePopup="updateRestorePopup"></restore-password-popup>

    <ProjectDesc @startGameClick="updateStartGamePopup"></ProjectDesc>
    <div class="monitoring-block mt-5">
      <block-title class="" v-bind:title="monitoringTitle"></block-title>
      <div class="monitoring-list">
        <monitoring-item class="m-3" v-if="monitoringData" v-for="item in monitoringData" v-bind:key="item.name"
                         v-bind:name="item.name" v-bind:online="item.online" v-bind:max-online="item.maxOnline"></monitoring-item>
        <h3 class="col-sm-12">Общий онлайн: {{sumOnline}}</h3>
        <h4 class="col-sm-12">Суточный рекорд: {{uniqueRecord}}</h4>
        <h4 class="col-sm-12">Рекорд дня: {{dayRecord}}</h4>
      </div>
    </div>

    <div v-if="salesData && salesData.length > 0" class="sales-block mt-5">
      <block-title class="" v-bind:title="salesTitle"></block-title>
      <div class="sale-items-list">
        <sales-item class="m-3" v-for="item in salesData" v-bind:key="item.name"
                         v-bind:saleItem="item"></sales-item>
      </div>
    </div>

    <block-title class="" v-bind:title="voteTitle"></block-title>
    <div class="voting-head">
      <h4>Голосуй за нас в топах и получай бонусы!</h4>
      <div class="tops">
        <a class="mr-3" href="https://topcraft.club/servers/8732/"><img src="https://topcraft.club/media/projects/8732/tops.png"></a>
        <a href="https://minecraftrating.ru/projects/diversemine/">
          <img src="//minecraftrating.ru/templates/theme/images/widgets/widget_vote.png"
               alt="Голосовать за проект DiverseMine - Давай к нам"
               title="Голосовать за проект DiverseMine - Давай к нам" width="88" height="31" />
        </a>
        <!--<a href="https://mctop.su/servers/5089/" target="_blank"><img src="https://mctop.su/media/projects/5089/tops.png"></a>-->
      </div>
    </div>
    <div class="voting-block">
      <vote-item class="m-3" v-if="voteData" v-for="i in voteData" v-bind:key="i.login" v-bind:user="i"></vote-item>
    </div>
  </div>
</template>

<script>
import ProjectDesc from "@/components/main/ProjectDesc";
import MonitoringItem from "@/components/main/MonitoringItem";
import BlockTitle from "@/components/common/BlockTitle";
import VoteItem from "@/components/main/VoteItem";
import SalesItem from "../components/main/SalesItem";

export default {
  name: 'Home',
  data() {
    return {
      monitoringTitle: "Мониторинг",
      voteTitle: "Голосование",
      salesTitle: "Скидки дня",
      startGameShow: false,
      authPopUpVisible: false,
      regPopupVisible: false,
      showRestore: false,
    }
  },
  components: {
    SalesItem,
    RestorePasswordPopup: () => import('@/components/pop-ups/RestorePasswordPopup'),
    AuthPopup: () => import('@/components/pop-ups/auth-popup'),
    RegisterPopup: () => import('@/components/pop-ups/register-popup'),
    VoteItem,
    BlockTitle,
    MonitoringItem,
    ProjectDesc,
    StartGamePopup: () => import('@/components/pop-ups/main/StartGamePopup'),
  },
  computed: {
    monitoringData() {
      return this.$store.getters["commonModule/getMonitoringData"]?.monitoringDto;
    },
    dayRecord() {
      return this.$store.getters["commonModule/getMonitoringData"]?.dayRecord;
    },
    uniqueRecord() {
      return this.$store.getters["commonModule/getMonitoringData"]?.dayUniqueRecord;
    },
    voteData() {
      return this.$store.getters["commonModule/getVoteData"];
    },
    salesData() {
      return this.$store.getters["commonModule/getSales"];
    },
    sumOnline() {
      let sum = 0;
      this.monitoringData?.forEach(item => {
        sum+= item.online >= 0 ? item.online : 0;
      })
      return sum;
    }
  },
  mounted() {
    this.$store.dispatch("commonModule/getMonitoring");
    this.$store.dispatch("commonModule/getVoters");
    this.$store.dispatch("commonModule/getSales");
  },
  methods: {
    updateStartGamePopup() {
      this.startGameShow = !this.startGameShow;
    },
    updateAuthPopup() {
      this.authPopUpVisible = !this.authPopUpVisible;
    },
    updateRegPopup() {
      this.regPopupVisible = !this.regPopupVisible;
    },
    updateRestorePopup() {
      this.showRestore = !this.showRestore;
    },
    closeRegAndOpenAuth() {
      this.regPopupVisible = !this.regPopupVisible;
      this.authPopUpVisible = !this.authPopUpVisible;
    },
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/main";

.monitoring-block {
  //background-color: $main-bg-color;

  h3 {
    color: $gray-text-color;
    font-family: $font-bold;
    margin-top: 15px;
  }
  h4 {
    color: $gray-text-color;
    font-family: $font-bold;
  }
}

.monitoring-list, .sale-items-list {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.tops {
  margin-bottom: 15px;
}

.voting-head {
  font-family: $font-bold;
  color: $gray-text-color;
}

.voting-block {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}
</style>
