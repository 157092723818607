import AuthService from "../services/AuthService";
import config from "@/config";
import api from "@/services/api";

const user = JSON.parse(localStorage.getItem(config.USER_KEY));
const initialState = user ? { status: {loggedIn: true }, user } :
            { status: {loggedIn: false }, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login( {commit}, user ) {
            return AuthService.login(user).then(user => {
                commit('loginSuccess', user);
                return Promise.resolve(user);
            },
            error => {
                commit('loginFailure');
                return Promise.reject(error);
            });
        },
        logout( {commit} ) {
            AuthService.logout();
            commit('logout')
        },
        register( {commit}, user) {
            return AuthService.register(user).then(response => {
                commit('registerSuccess');
                return Promise.resolve(response);
            }, error => {
                commit('registerFailure');
                return Promise.reject(error);
            })
        },
        sendRestoreMail( {commit}, mail) {
            return api.post(config.API_URL + 'auth/sendRestore/'+ mail).then(response => {
                return Promise.resolve(response.data);
            }, error => {
                commit('registerFailure');
                return Promise.reject(error);
            })
        },
        restorePassword( {commit}, token) {
            return api.post(config.API_URL + 'auth/restorePassword/'+ token).then(response => {
                return Promise.resolve(response.data);
            }, error => {
                commit('registerFailure');
                return Promise.reject(error);
            })
        },
        verifyRegistration( {commit}, token) {
            return api.post(config.API_URL + 'auth/reg-verify/'+ token).then(response => {
                return Promise.resolve(response.data);
            }, error => {
                commit('registerFailure');
                return Promise.reject(error);
            })
        },
        //update by refresh token
        refreshToken( {commit}, accessToken ) {
            commit('refreshToken', accessToken);
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        refreshToken(state, data) {
            state.status.loggedIn = true;
            state.user = data;
        }
    },
    getters: {
        getLoggedUser(state) {
            return state.user;
        },
        getCurUser(state) {
            return state.user.user;
        }
    }
}