<template>
  <a class="logotype" style="text-decoration: none" href="/">
    <div class="logotype-name">
      <div class="logotype-name-first">DIVERSE</div>
      <div class="logotype-name-second">MINE</div>
    </div>
  </a>
</template>

<script>
export default {
  name: "Logotype"
}
</script>

<style lang="scss" scoped>
@import "src/assets/main";

$logotype-size-font: 27px;
$logotype-weight-font: bold;

$logotype-first-color: #FFF;
$logotype-second-color: $main-blue-color;

@mixin bars-element {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: $logotype-first-color;
  content: '';
  display: block;
  border-radius: 4px;
}

.logotype {
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    transform: scale(1.1);
    transition: 0.3s;
  }

  &-bars {
    height: 16px;
    width: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 8px;

    span {
      @include bars-element;
    }

    &::before {
      @include bars-element;
      top: 0;
    }

    &::after {
      @include bars-element;
      bottom: 0;
    }
  }

  &-name {
    display: flex;
    font-size: 31px;
    font-weight: bold;
    font-family: $font-bold;

    &-first {
      color: $logotype-first-color;
    }

    &-second {
      color:$logotype-second-color;
    }
  }
}
</style>