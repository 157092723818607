import config from "@/config";
import api from "@/services/api";

const initialState = {voteTop: null}

export const playersTopModule = {
    namespaced: true,
    state: initialState,
    actions: {
        getTopVoters({commit}, page) {
            api.get(config.API_URL + 'vote/top', {
                params: {
                    page: 0,
                    size: config.MAX_RESULT_PER_PAGE
                }
            }).then(response => {
                commit('setTopVoters', response.data);
                return Promise.resolve(response.data);
            }).then(success => {
                return Promise.resolve(success.data);
            }, error => {
                return Promise.reject(error);
            });
        }
    },
    mutations: {
      setTopVoters(state, data) {
          state.voteTop = data;
      }
    },
    getters: {
        getTopVoters(state) {
            return state.voteTop;
        }
    }

}