const config = {
    PROJECT_DOMAIN: "DiverseMine.Su",
    PROJECT_NAME: "DiverseMine",
    API_URL: "https://api.diversemine.su/api/",

    TOKEN_KEY: "auth-token",
    USER_KEY: "auth-user",
	REF_KEY: "ref-user",
    USER_CD_KEY: "user-cd-key",
    SPAWN_TP_KEY: "spawn-tp-key",

    REAL_MONEY_NAME: "коинов",
    REAL_MONEY_ARRAY: Array.of("коин", "коина","коинов"),

    GAME_MONEY_NAME: "экон",
    GAME_MONEY_ARRAY: Array.of("экон", "экона","эконов"),

    BONUS_MONEY_NAME: "бонусов",
    BONUS_MONEY_ARRAY: Array.of("бонус", "бонуса","бонусо"),

    MAX_RESULT_PER_PAGE:  15,

    CAPTCHA_SITE_KEY: '6LdrEzAeAAAAAP5Br2M2dDa2RBm_n3EgNDOFlYTA',
    USE_RECAPTCHA: true,
}
export default config;
