<template>
  <div class="vote-block">
    <img class="vote-img" v-bind:src="skinHeadUrl" alt="голова, ы">
    <div class="text-voter">
      <div class="login-text mt-3">{{user.login}}</div>
      <span>проголосовал за нас {{user.lastVoteTime}}</span>
    </div>
  </div>
</template>

<script>
import config from "@/config";

export default {
  name: "VoteItem",
  data() {
    return {
      skinHeadUrl: config.API_URL + this.user?.skinUrl,
    }
  },
  props: {
    user: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/main";

.vote-block {
  background-color: $input-color;
  width: 175px;
  color: $gray-text-color;
  border-radius: 7px;
  padding: 11px 5px;
}
.vote-img {
  border-radius: 100%;
  border: 2px solid $main-blue-color;
}

.login-text {
  margin-top: 5px;
  color: $main-blue-color;
  font-family: $font-bold;
}

.text-voter {
}

</style>