<template>
  <div id="app" @mousemove="parallaxBlocks" toggleable="lg">
    <div class="__header_block">
        <div ref="header" class="main-header">
          <Header></Header>
          <div class="main-header-background">
            <div ref="whiteElement" class="main-header-background-white"></div>
            <div ref="blueElement" class="main-header-background-blue">
              <span></span>
            </div>
          </div>
        </div>
    </div>

    <b-container class="main-container">
      <router-view/>
    </b-container>

    <div ref="blocks" class="main-parallax-image d-none d-xl-block animate__animated animate__bounceInDown">
      <img class="mt-3" style="max-width:100%;" src="./assets/img/blocks-main-layout.webp" alt="Кубики">
    </div>
    <footer class="footer-main mt-5">
      <b-row>
        <b-col sm="4">
          <div class="footer-domain">
            {{ domainName + " | " + new Date().getFullYear() }} <br>
            По вопросам и предложениям: <b>admin@diversemine.su</b>
          </div>
          <div class="minecraft-info mt-3">
            Мы предоставляем ознакомительный бесплатный вариант игры Minecraft,
            купить лицензионную версию игры можно <a href="https://www.minecraft.net/">здесь</a>
          </div>
        </b-col>
        <b-col sm="4">
          <div class="footer-menu">
            <div class="footer-block">Информация</div>
            <ul class="mt-1">
              <li><a href="/rules">Правила</a></li>
              <li><a href="/donate/classic">Описание привилегий</a></li>
            </ul>
          </div>
        </b-col>
        <b-col sm="4">
          <div class="footer-menu">
            <div class="footer-block">Полезные ссылки</div>
            <ul class="mt-1">
              <li><a href="https://vk.com/diversemine" target="_blank">Группа ВК</a></li>
              <li><a href="https://discord.gg/a5cKVq3hM3" target="_blank">Дискорд</a></li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </footer>
  </div>
</template>

<script>
import config from "./config";
import Header from "@/components/main/Header"

export default {
  data: function () {
    return {
      domainName: config.PROJECT_DOMAIN,
      authPopUpVisible: false,
      regPopupVisible: false,

      offsetX: 0,
      offsetY: 0,
    }
  },
  components: {
    Header
  },
  computed: {
    isAdmin() {
      if(!this.loggedIn) return false;
      return this.loggedIn.group.toUpperCase() === 'ADMIN'
    },
    loggedIn() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
  },
  methods: {
    parallaxBlocks(event) {
      const element = this.$refs.blocks;

      //element.style.translate = this.offsetX + 'px';

      // let offsetX = (event.clientX / window.innerWidth * 70) - 40;
      // let offsetY = (event.clientY / window.innerHeight * 40) - 25;

      this.offsetX = (event.clientX / window.innerWidth * 70) - 40;
      this.offsetY = (event.clientY / window.innerHeight * 40) - 25;

      element.style.bottom = this.offsetX + 'px';
      element.style.right = this.offsetY + 'px';
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/').catch(()=>{});
      this.$toast.success("Вы вышли из своего аккаунта!")
    },
  }
}
</script>

<style lang="scss">

@import "assets/main";

#app {
  font-family: $font-medium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.__header_block {
  background-color: $dark-gray;
  color: $gray-text-color;
  font-family: $font-bold;
  font-size: 19px;
  box-shadow: 1px -1px 15px 0px #19283c;
}

.main-container {
  min-height: 75vh;
}

.footer-main {
  box-shadow: 1px -1px 15px 0px #19283c;
  background-color: $dark-gray;
  color: white;
  font-size: 17px;
  font-weight: bold;
  padding: 0 35px 31px 35px;
}

.footer-domain {
  margin-top: 31px;
  padding-bottom: 7px;
}

.main-parallax-image {
  transition: 0.3s;
  position: absolute;
  display: flex;
  float: right;
  z-index: -1;
  margin-right: 35px;
  bottom: 0;
  right: 0;
}

.footer-block {
  text-transform: uppercase;
}

.footer-menu {
  margin-top: 31px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    a {
      color: $gray-text-color;
      text-decoration: none;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        color: white;
        transition: 0.3s;
      }
    }
  }
}

.minecraft-info {
  color: $gray-text-color;
}


/* bootstrap*/

</style>
