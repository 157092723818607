<template>
  <b-row class="project-desc animate__animated animate__zoomInDown">
    <b-col sm="5">
      <p class="desc-info-first">- Искал уникальный сервер для комфортной игры с друзьями?</p>
      <h2 class="project-name-first">Diverse<span class="project-name-second">Mine</span> - Вливайся к нам и насладись серверами</h2>

      <p class="desc-info">Играй на нашем фантастическом проекте,
        на котором созданы уникальные сервера с интересными сборками, с самописными модами и захватывающим геймплеем!</p>

      <div class="desc-buttons">
        <div @click="updateStartGamePopup" class="trapezoid-group-btn mt-3">
          <div class="play-btn"><b-icon icon="play"></b-icon></div>
          <a class="tr-button">
            <span v-if="!isLoggedIn">Начать играть</span>
            <span v-else>Скачать лаунчер</span>
          </a>
        </div>

        <div @click="goToDs" class="trapezoid-group-btn mt-3 ml-3">
          <a class="discord-tr-button">
            <span><i class="fab fa-discord"></i></span>
          </a>
        </div>

        <div @click="goToVk" class="trapezoid-group-btn mt-3 ml-3">
          <a class="vk-tr-button">
            <span><i class="fab fa-vk"></i></span>
          </a>
        </div>
      </div>

    </b-col>
    <b-col sm="7" class="desc-image d-none d-xl-block">
      <img ref="descImg" class="desc-image" src="@/assets/img/img-desc.webp" alt="воу">
    </b-col>

  </b-row>
</template>

<script>
export default {
  name: "ProjectDesc",
  data() {
    return {
      startGameShow: false,
    }
  },
  props: {
    offsetX: {
      default: 0
    },
    offsetY: {
      default: 0
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/getLoggedUser"];
    }
  },
  methods: {
    goToDs() {
      window.open("https://discord.gg/a5cKVq3hM3", '_blank');
    },
    goToVk() {
      window.open("https://vk.com/diversemine", '_blank');
    },
    updateStartGamePopup() {
      this.$emit("startGameClick")
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/main";

.project-desc {
  margin-top:115px;
  text-align: left;
}

.project-name-first {
  font-family: $font-bold;
  color: white;
}

.project-name-second {
  color: $main-blue-color;
}

.desc-info-first {
  margin-top: 115px;
  color: $gray-text-color;
  font-family: $font-medium;
  font-size: 14px;
}

.desc-info {
  @extend .desc-info-first;
  font-size: 17px;
  margin-top: 15px;
}

.desc-image {
  height: 495px;
  width: 655px;
}

.trapezoid-group-btn {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    transition: 0.3s;
    transform: scale(1.1);
    color: white;
  }

  a {
    text-decoration: none;
  }
}

.tr-button {
  display: flex;
  align-items: center;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background: white;
  padding: 15px 10px;
  transform: skewX(-9deg);
  text-decoration: none;
  text-transform: uppercase;
  color: $main-bg-color;
  font-family: $font-bold;

  span {
    transform: skewX(9deg);
  }

  &:hover {
    color: $main-bg-color;
  }
}

.play-btn {
  margin-top: 1px;
  display: flex;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  transform: skewX(-9deg);
  background: $main-blue-color;
  padding: 14px 15px;
  color: white;
  font-size: 27px;
  font-weight: bold;
}

.vk-tr-button {
 @extend .tr-button;
  border-radius: 5px;
  padding: 15px;
  background-color: $main-blue-color;
  color: $main-blue-color;

  i {
    background-color: white;
    padding: 3px;
    border-radius: 3px;
  }

  &:hover {
    color: $main-blue-color;
  }
}

.discord-tr-button {
  @extend .vk-tr-button;
  background-color: white;
  color: white;

  i {
    background-color: $main-bg-color;
  }

  &:hover {
    color: white;
  }
}

.desc-buttons {
  margin-top: 55px;
  display: inline-flex;
  flex-flow: row wrap;
}

</style>