import config from "@/config";
import api from "@/services/api";

const initialState = { servers: null, donateGroups: null, donateCases: null, unbanCost: 0, groupSales: null,
    coinMultiplier: 2, logs: null, referrals: null};

export const cabinet = {
    namespaced: true,
    state: initialState,
    actions: {
        getCabinetInfo({commit}) {
            return api.get(config.API_URL + 'cabinet/info').then(success => {
                commit('getCabinetInfo', success.data);
                return Promise.resolve(success.data);
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        getDonateGroups({commit}, id) {
            return api.get(config.API_URL + 'cabinet/donategroups/getGroups', {
                params: {
                    srvId: id
                }
            }).then(success => {
                commit('setDonateGroups', success.data);
                return Promise.resolve(success.data);
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        setDonateGroups({commit}, value) {
            commit('setDonateGroups', value);
        },
        getDonateCases({commit}, id) {
            return api.get(config.API_URL + 'cabinet/donatecases/getCases', {
                params: {
                    srvId: id
                }
            }).then(success => {
                commit('setDonateCases', success.data);
                return Promise.resolve(success.data);
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        setDonateCases({commit}, value) {
            commit('setDonateCases', value);
        },
        getHistory({commit}, page) {
            return api.get(config.API_URL + 'cabinet/history', {
                params: {
                    page: page-1,
                    count: config.MAX_RESULT_PER_PAGE
                }
            }).then(success => {
                commit('getHistory', success.data);
                return Promise.resolve(success.data);
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        getReferrals({commit}) {
            return api.get(config.API_URL + 'cabinet/referrals').then(success => {
                commit('setReferrals', success.data);
                return Promise.resolve(success.data);
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        doPrefixChange({commit}, request) {
            return api.post(config.API_URL + 'cabinet/doPrefixChange', request).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
               return Promise.reject(error)
            });
        },
        doSpawnTP({commit}, id) {
            return api.post(config.API_URL + 'cabinet/doSpawnTeleport/' + id).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        doUnban({commit}, id) {
            return api.post(config.API_URL + 'cabinet/doUnban/' + id).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        doMoneyConvert({commit}, money) {
            return api.post(config.API_URL + 'cabinet/convertMoneyToGameMoney', {money}).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        createPayment({commit}, money) {
            return api.post(config.API_URL + 'pay/genPay/'+ money).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        buyDonateGroup({commit}, data) {
            return api.post(config.API_URL + 'cabinet/donategroups/buyDonateGroup', data).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        buyDonateCase({commit}, data) {
            return api.post(config.API_URL + 'cabinet/donatecases/buyDonateCase/', data).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        activateGiftCode({commit}, giftCode) {
            return api.post(config.API_URL + 'cabinet/activatePromoCode/' + giftCode).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
        giveGameMoney({commit}, body) {
            return api.post(config.API_URL + 'cabinet/giveGameMoney/', body).then(response => {
                return response.data;
            }).then(success => {
                return Promise.resolve(success);
            }, error => {
                return Promise.reject(error)
            });
        },
    },
    mutations: {
        getCabinetInfo(state, data) {
            state.servers = data.servers;
            state.donateCases = data.donateCases;
            state.coinMultiplier = data.coinMultiplier;
            state.unbanCost = data.unbanPrice;
            state.groupSales = data.groupSales;
        },
        getHistory(state, data) {
            state.logs = data;
        },
        setReferrals(state, data) {
          state.referrals = data;
        },
        setDonateCases(state, data) {
            state.donateCases = data;
        },
        setDonateGroups(state, data) {
            state.donateGroups = data;
        }
    },
    getters: {
        getCoinMultiplier(state) {
            return state.coinMultiplier;
        },
        getUnbanPrice(state) {
            return state.unbanCost;
        },
        getDonateGroupDurationSales(state) {
            return state.groupSales;
        },
        getDonateCases(state) {
            return state.donateCases;
        },
        getDonateGroups(state) {
            return state.donateGroups;
        },
        getServers(state) {
            return state.servers;
        },
        getHistory(state) {
            return state.logs;
        },
        getReferrals(state) {
            return state.referrals;
        }
    }
}